











































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { Meeting } from '@tada-team/tdproto-ts'
import { QDialog } from 'quasar'

@Component({
  components: {
    BaseBtn: () => import('@/components/UI/BaseBtn.vue'),
    IconX: () => import('@tada/icons/dist/IconX.vue'),
  },
})
export default class MeetingRemoveDialog extends Vue {
  @Prop({
    type: Meeting,
    required: true,
  }) readonly meeting!: Meeting

  @Ref() readonly dialog!: QDialog

  public show (): void {
    this.dialog.show()
  }

  private cancel (): void {
    window.goal('Calendar', {
      Calendar: 'Отмена удаления встречи — кнопка «Отменить»',
    })
    this.hide()
  }

  private hide (): void {
    this.dialog.hide()
  }

  private onDialogHide () {
    this.$emit('hide')
  }

  private get removeDescription () {
    const descTitle = this.$t('meetings.removeDialog.description').toString()
    const desc = `${descTitle}`
    return this.meeting.freq
      ? this.$t('meetings.removeDialog.descriptionRepeatable').toString()
      : desc
  }

  private formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
    day: '2-digit',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
  })

  private get description () {
    return `${this.formatter.format(new Date(this.meeting.startAt))} ${this.meeting.displayName}?`
  }

  private remove () {
    this.$emit('ok')
    window.goal('Calendar', { Calendar: 'Успешное удаление встречи — кнопка «Удалить»' })
    this.hide()
  }

  private get title () {
    return this.meeting.freq
      ? this.$t('meetings.removeDialog.titleRepeatable').toString()
      : this.$t('meetings.removeDialog.title').toString()
  }
}
